<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div style="background: #ecf0f5">
    <v-container fluid>
      <v-row style="margin-top: -20px" dense>
        <v-col>
          <v-breadcrumbs :items="items" light>
            <template v-slot:divider>
              <v-icon small>$vuetify.icons.values.mdiChevronRight</v-icon>
            </template>
          </v-breadcrumbs>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span><v-icon large class="mr-3">$vuetify.icons.values.mdiChartPie</v-icon></span>
          <span>{{$t('msg.results_page_heading_name')}}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-app-bar dark color="#8c92dc">
              <v-icon>$vuetify.icons.values.mdiMagnify</v-icon>
              <v-toolbar-title>{{$t('msg.filters')}}</v-toolbar-title>
            </v-app-bar>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" md="4" lg="4">
                  <v-select v-model="filterBox.assessment" :items="assessmentDropDown"
                            :label="$t('msg.assessmentDropdownLabel')" clearable outlined>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                  <v-select v-model="filterBox.organization" :items="organizationDropDown"
                            :label="$t('msg.organizationDropdownLabel')" clearable outlined>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                  <v-select v-model="filterBox.status" :items="statusDropDown"
                            :label="$t('msg.statusDropdownLabel')" @change="checkForStatus" clearable outlined>
                  </v-select>
                </v-col>

              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formatStartDate"
                        label="From"
                        clearable
                        readonly
                        outlined
                        prepend-icon="$vuetify.icons.values.mdiCalendar"
                        v-on="on"
                        @click:clear="clearDateFields"
                        :disabled="isDateFilterDisabled"
                        @mouseup="filterBox.endDate = null"
                      />
                    </template>
                    <v-date-picker v-model="filterBox.startDate" :max="date" no-title scrollable @input="menu = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formatEndDate"
                        label="To"
                        persistent-hint
                        prepend-icon="$vuetify.icons.values.mdiCalendar"
                        v-on="on"
                        clearable
                        readonly
                        @click:clear="filterBox.endDate = null"
                        :disabled="!filterBox.startDate || isDateFilterDisabled"
                        outlined
                      />
                    </template>
                    <v-date-picker v-model="filterBox.endDate" :min="filterBox.startDate" :max="date"
                                   :show-current="filterBox.startDate" :reactive="true"
                                   no-title scrollable @input="menu2 = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4" justify-self="end"
                       class="d-flex justify-lg-end justify-md-end justify-center mt-3">
                  <v-btn color="#5660a5" dark class="mr-3" @click="filter">{{$t('msg.search')}}</v-btn>
                  <v-btn color="#5660a5" dark @click="reset">{{$t('msg.reset')}}</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8" lg="8">
                  <p v-show="isDateFilterDisabled">* The Date Filter is not available for the selected Status.</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="overlay">
      <v-dialog
        v-model="overlay"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="black"
          dark
        >
          <v-card-text>
            {{$t('msg.loading')}}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-overlay>
    <v-container fluid style="padding-bottom: 70px">
      <v-row>
        <v-col>
          <v-card>
            <v-app-bar
              dark
              color="#8c92dc">
              <v-toolbar-title>{{$t('msg.table_heading')}}</v-toolbar-title>
              <v-row>
                <v-col cols="2" sm="4" md="7" lg="8"></v-col>
                <v-col cols="10" sm="8" md="5" lg="4" class="text-right">
                  <v-dialog max-width="450" persistent v-model="dialog">
                    <template v-slot:activator="{ on }">
                      <v-btn color="#5660a5" dark v-on="on">{{$t('msg.applied_filter_label')}}</v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline">{{$t('msg.applied_filter_card_title')}}</v-card-title>
                      <v-card-text>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                            <tr>
                              <td>{{$t('msg.assessmentDropdownLabel')}}</td>
                              <td>:</td>
                              <td v-if="!pagination.assessment">{{$t('msg.text_all_assessments')}}</td>
                              <td v-else>
                                <span :key="assessment.value" v-for="assessment in assessmentDropDown">
                                  <span v-if="pagination.assessment === assessment.value">{{assessment.text}}</span>
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td>{{$t('msg.organizationDropdownLabel')}}</td>
                              <td>:</td>
                              <td v-if="!pagination.organization">{{$t('msg.text_all_organizations')}}</td>
                              <td v-else>
                                <span :key="organization.value" v-for="organization in organizationDropDown">
                                  <span v-if="pagination.organization === organization.value">{{organization.text}}</span>
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td>{{$t('msg.statusDropdownLabel')}}</td>
                              <td>:</td>
                              <td v-if="!pagination.status">{{$t('msg.text_all_status')}}</td>
                              <td v-else>
                                <span :key="getStatus.value" v-for="getStatus in statusDropDown">
                                  <span v-if="pagination.status === getStatus.value">{{getStatus.text}}</span>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>{{$t('msg.dropdown_text_from')}}</td>
                              <td>:</td>
                              <td v-if="!pagination.startDate">{{$t('msg.text_all_dates')}}</td>
                              <td v-else>
                                <span>{{pagination.formattedStartDate}}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>{{$t('msg.dropdown_text_to')}}</td>
                              <td>:</td>
                              <td v-if="!pagination.endDate">{{$t('msg.text_all_dates')}}</td>
                              <td v-else>
                                <span>{{pagination.formattedEndDate}}</span>
                              </td>
                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card-text>
                      <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn @click="dialog = false" color="green darken-1" text>{{$t('msg.close_button')}}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-app-bar>
            <v-card-title>
              <v-row>
                <v-col md="8" lg="8" class="d-none d-md-flex d-lg-flex"></v-col>
                <v-text-field hide-details :placeholder="$t('msg.search_placeholder')" single-line v-model="search"
                              v-on:keyup.enter="filter">
                </v-text-field>
                <v-btn class="mt-2" color="#5660a5" dark @click="filter">
                  <v-icon>$vuetify.icons.values.mdiMagnify</v-icon>
                </v-btn>
              </v-row>
            </v-card-title>
            <v-row dense>
              <v-col>
                <v-data-table :headers="headers" :items="table.tableData" :options.sync="pagination"
                              :disable-sort="true" :items-per-page=pagination.itemPerPage @update:options="paginate"
                              :server-items-length=table.totalLength
                              :footer-props="{
                            'items-per-page-options': pagination.itemPerPageOptions,
                            'show-current-page':true
                            }">
                  <template v-slot:item.hasGeneralScores="{ item }">
                    <span v-if="item.hasGeneralScores && (!item.reportsBlockedForSchedule && !item.reportsBlockedForIndividual)">
                      <span v-if="checkArray(item.testTakerId,item.idNumber)">
                        <v-icon v-for="report in item.applicableReports" style="cursor: pointer" color="green"
                                v-bind:key="report.reportCode+'-'+item.testTakerId"
                                @click.native="downloadReport(item.testTakerId,item.idNumber,report.reportCode,report.language)">
                          $vuetify.icons.values.mdiFileDownloadOutline
                        </v-icon>
                      </span>
                      <span v-else>
                        <v-progress-circular indeterminate :size="20" color="#3c8dbc"></v-progress-circular>
                      </span>
                    </span>
                    <span v-else>
                      <v-icon disabled>$vuetify.icons.values.mdiFileDownloadOutline</v-icon>
                    </span>
                  </template>
                  <template v-slot:item.startDateFormatted="{ item }">
                    <span v-if="item.startDateFormatted">
                      {{ item.startDateFormatted }}
                    </span>
                    <span class="text-center" v-else>
                      <v-icon>$vuetify.icons.values.mdiMinus</v-icon>
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-card v-if="isShowExportButtonEnabled" class="dashboard-datatable-footer dashboardFooterBottomMargin">
              <v-card-actions class="dashboard-datatable-footer-actions">
                <v-btn class="ma-2" color="#5660a5" dark type="button" @click="exportData" :disabled="exporting">
                  <v-progress-circular v-show="exporting" indeterminate :size="20" color="white">
                  </v-progress-circular>
                  <span v-show="exporting">&nbsp;</span>{{$t('msg.export_button')}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="reportDownloadError" :bottom="true" dark color="#3c8dbc" :right="true" :timeout="6000"
                :vertical="true">
      <span v-html="$t('msg.report_unavailable')"></span>
      <v-btn dark text @click="reportDownloadError = false" v-html="$t('msg.close_label')"></v-btn>
    </v-snackbar>
    <v-snackbar v-model="exportDataDownloadError" :bottom="true" color="info" :right="true" :timeout="6000"
                :vertical="true">
      <span v-html="$t('msg.export_unavailable')"></span>
      <v-btn dark text @click="exportDataDownloadError = false" v-html="$t('msg.close_label')"></v-btn>
    </v-snackbar>

    <v-snackbar v-model="selectAssessmentForDataExportError" :bottom="true" color="info" :right="true" :timeout="6000"
                :vertical="true">
      <span v-html="$t('msg.export_assessment_for_export')"></span>
      <v-btn dark text @click="selectAssessmentForDataExportError = false" v-html="$t('msg.close_label')"></v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { dashboardService, downloadFileService } from '../_services'
import fileSaver from 'file-saver'

export default {
  name: 'Results',
  data () {
    return {
      overlay: false,
      exporting: false,
      exportDataDownloadError: false,
      selectAssessmentForDataExportError: false,
      assessmentDropDown: [],
      organizationDropDown: [],
      statusDropDown: [],
      requestDataArray: [''],
      date: new Date().toJSON(),
      loadingDataTable: false,
      reportDownloadError: false,
      endDateFormatted: '',
      startDateFormatted: '',
      menu: false,
      menu2: false,
      dialog: false,
      filterBox: {
        assessment: '',
        organization: '',
        status: '',
        startDate: '',
        endDate: ''
      },
      search: '',
      pagination: {
        itemsPerPage: 10,
        itemPerPageOptions: [10],
        assessment: '',
        organization: '',
        status: '',
        startDate: '',
        endDate: '',
        formattedStartDate: '',
        formattedEndDate: '',
        searchedText: ''
      },
      table: {
        tableData: [],
        totalLength: 0
      },
      items: [
        {
          text: 'Home',
          disabled: true,
          href: 'breadcrumbs_dashboar'
        },
        {
          text: 'Result',
          disabled: true,
          href: 'breadcrumbs_link_1'
        }
      ],
      headers: [
        { text: this.$t('msg.table_header_no'), align: 'center', sortable: false, value: 'slNo' },
        { text: this.$t('msg.table_header_name'), align: 'left', value: 'fullname' },
        { text: this.$t('msg.table_header_employeeId'), align: 'center', value: 'idNumber' },
        { text: this.$t('msg.table_header_assessment'), align: 'center', value: 'assessmentName' },
        { text: this.$t('msg.table_header_organization'), align: 'center', value: 'organizationName' },
        { text: this.$t('msg.table_header_status'), align: 'center', value: 'completionStatus' },
        { text: this.$t('msg.table_header_assessment_date'), align: 'center', value: 'startDateFormatted' },
        { text: this.$t('msg.table_header_report'), align: 'center', value: 'hasGeneralScores' }
      ]
    }
  },
  methods: {
    populateDataForSelects: function () {
      this.overlay = true
      dashboardService.fetchDataForResultPageDropDowns()
        .then(response => {
          if (response.dataResponse) {
            if (response.dataResponse.Status) {
              this.statusDropDown = response.dataResponse.Status
              this.assessmentDropDown = response.dataResponse.Assessments
              this.organizationDropDown = response.dataResponse.Organizations
            }
          }
          this.overlay = false
        }).catch(error => {
          this.overlay = false
          /* eslint-disable no-console */
          console.log('loggin response ' + error)
        /* eslint-enable no-console */
        })
    },
    getDataFromApi: function () {
      this.overlay = true
      let data = {
        pageSize: this.pagination.itemsPerPage,
        pageNumber: this.pagination.page,
        searchText: this.search,
        assessment: this.pagination.assessment,
        organization: this.pagination.organization,
        status: this.pagination.status,
        startDate: this.pagination.startDate,
        endDate: this.pagination.endDate
      }
      dashboardService.fetchResultListData(data)
        .then(response => {
          if (response.dataResponse && response.dataResponse.data) {
            this.table.tableData = response.dataResponse.data.tableContent
            this.table.totalLength = response.dataResponse.data.totalRows
          }
          this.overlay = false
        }).catch(error => {
          this.overlay = false
          /* eslint-disable no-console */
          console.log('loggin response ' + error)
        /* eslint-enable no-console */
        })
    },
    paginate: function () {
      this.getDataFromApi()
    },
    filter: function () {
      this.pagination.assessment = this.filterBox.assessment
      this.pagination.organization = this.filterBox.organization
      this.pagination.status = this.filterBox.status
      this.pagination.searchedText = this.search
      this.pagination.startDate = this.filterBox.startDate
      this.pagination.endDate = this.filterBox.endDate
      this.pagination.formattedStartDate = this.formatStartDate
      this.pagination.formattedEndDate = this.formatEndDate
      this.pagination.page = 1
      this.paginate()
    },
    reset: function () {
      this.filterBox.assessment = ''
      this.pagination.assessment = ''
      this.filterBox.organization = ''
      this.pagination.organization = ''
      this.filterBox.status = ''
      this.pagination.status = ''
      this.pagination.formattedEndDate = ''
      this.pagination.formattedStartDate = ''
      this.pagination.startDate = ''
      this.pagination.endDate = ''
      this.pagination.page = 1
      this.filterBox.startDate = ''
      this.filterBox.endDate = ''
      this.search = ''
      this.pagination.searchedText = ''
      this.paginate()
    },
    downloadReport: function (ttid, idNumber, reportType, reportLanguage) {
      this.reportDownloadError = false
      let loaderString = ttid + '-' + idNumber
      if (this.requestDataArray.indexOf(loaderString) < 0) {
        this.requestDataArray.push(loaderString)
      }
      downloadFileService.downloadReport({ id: ttid, reportType: reportType, language: reportLanguage })
        .then(response => {
          if (response) {
            fileSaver.saveAs(response, idNumber + '.pdf')
          }
          this.requestDataArray.splice(this.requestDataArray.indexOf(loaderString), 1)
          this.loadingDataTable = false
        }).catch(error => {
          this.loadingDataTable = false
          this.requestDataArray.splice(this.requestDataArray.indexOf(loaderString), 1)
          this.reportDownloadError = true
          /* eslint-disable no-console */
          console.log('loggin response ' + error)
        /* eslint-enable no-console */
        })
    },
    exportData: function () {
      if (this.exporting) {
        return false
      }
      this.exporting = true
      if (!this.pagination.assessment) {
        this.selectAssessmentForDataExportError = true
        this.exporting = false
        return
      }
      let data = {
        pageSize: this.pagination.itemsPerPage,
        pageNumber: this.pagination.page,
        searchText: this.search,
        assessment: this.pagination.assessment,
        organization: this.pagination.organization,
        status: this.pagination.status,
        startDate: this.pagination.startDate,
        endDate: this.pagination.endDate
      }
      downloadFileService.downloadExcel(data)
        .then(response => {
          if (response) {
            fileSaver.saveAs(response, 'excel_export.xlsx')
            this.exporting = false
          }
        })
        .catch(error => {
          this.exporting = false
          console.error('Error while exporting data: ', error)
        })
    },
    checkArray: function (ttid, idNumber) {
      return this.requestDataArray.indexOf(ttid + '-' + idNumber) < 0
    },
    clearDateFields: function () {
      this.filterBox.startDate = null
      this.filterBox.endDate = null
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    checkForStatus: function () {
      if (this.filterBox.status === 'Not Started' || this.filterBox.status === 'Token Expired') {
        this.filterBox.startDate = null
        this.filterBox.endDate = null
      }
    }
  },
  computed: {
    isDateFilterDisabled: function () {
      return (this.filterBox.status === 'Not Started' || this.filterBox.status === 'Token Expired')
    },
    isShowExportButtonEnabled: function () {
      return this.$store.state.home.user.showExportButton && this.pagination.assessment !== 'T0070'
    },
    formatStartDate: {
      get: function () {
        return this.formatDate(this.filterBox.startDate)
      },
      set: function () {}
    },
    formatEndDate: {
      get: function () {
        return this.formatDate(this.filterBox.endDate)
      },
      set: function () {}
    }
  },
  mounted () {
    this.$store.dispatch('home/setCurrentPage', 'results')
    this.$cookies.set('sp_current_page', 'results')
    this.populateDataForSelects()
  }
}
</script>

<style scoped>
  .v-breadcrumbs {
    padding: 7px 0px 0px 0px !important;
  }
</style>
<style>
  .v-input input{
    cursor: pointer !important;
  }
  .v-input__slot{
    cursor: default !important;
  }
  .removeFilterBottomMargin{
    margin-bottom: -20px;
  }
  .dashboardFooterBottomMargin{
    margin-bottom: 100px;
  }
</style>
